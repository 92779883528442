import React from "react";
import { Grid, GridRow, GridCol } from "@naf/grid";
import { Text } from "@naf/text";
import styled from "styled-components";
import { ProductInfo } from "../constant/product.constant";
import PropTypes from 'prop-types';

const Style = styled.div`
  .order-details-container {
    height: 210px;
    background: #fffaec;
    border-radius: 4px;
    padding: 32px;
  }
  .memebership-type-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 3px;
  }
  .total {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    border-top: 1px solid #e5e5e5;
  }
  .grid-gap-28 {
    grid-gap: 28%;
}
`;
export default function Invoice(props) { 
  const { customer, invoice } = props;

  const salesOrderLines = invoice?.order?.SalesOrder?.SalesOrderLines || [];

  // Calculate the total net price
  const with_roadside_assistanceNetPrice = salesOrderLines.reduce((total, line) => total + line.netPrice, 0); 
  const filteredLines = salesOrderLines.filter((line) => line.productNumber === "1121");
  // Calculate the total net price for productNumber "1121"
  const without_roadside_assistanceNetPrice = filteredLines.reduce((total, line) => total + line.netPrice, 0);
  
  return (
    <Style>
      <Grid>
        <GridRow className="grid-gap-28">
          <GridCol m="8" l="8" xl="8" className="pt-0 pb-0">
            {invoice?.invoiceId &&
              <>
                <div style={{ marginBottom: "40px" }}>
                  <Text style={{ margin: "0px" }} tag="h2" variant="header2">
                    {customer?.basic?.firstName} {customer?.basic?.lastName} er NAF
                    medlem!
                  </Text>
                </div>
                <div style={{ paddingBottom: "14px" }}>
                  <Text style={{ margin: "0px" }} tag="h3" variant="header3">
                    Medlemsnummeret er:{" "}                  
                    {customer?.customerNumber}
                  </Text>
                </div>
                <div>
                  <Text style={{ margin: "0px" }} variant="body">
                    En e-post med bekreftelse blir snart sendt til
                    {customer?.basic?.email}
                  </Text>
                </div>
              </>
            }
              {invoice?.hasUnpaidInvoice &&
              <>
                <div style={{ marginBottom: "40px" }}>
                  <Text style={{ margin: "0px" }} tag="h2" variant="header2">
                   {customer?.basic?.firstName} {customer?.basic?.lastName} 
                  </Text>
                </div>
                <div style={{ paddingBottom: "14px" }}>
                  <Text style={{ margin: "0px" }} tag="h3" variant="header3">
                    Medlemsnummeret er: {customer?.customerNumber}                    
                  </Text>
                </div>
                <div>
                  <Text style={{ margin: "0px" }} variant="body">
                  Faktura vil bli tilsendt i posten og en e-post med 
                  bekreftelse blir snart sendt til {customer?.basic?.email}
                  </Text>
                </div>
              </>
            }
          </GridCol>
          <GridCol m="4" l="4" xl="4">
            <div className="order-details-container">
              <Text style={{ margin: "0px" }} tag="h3" variant="header3">
                Kvittering
              </Text>
              {invoice.productType ===
                ProductInfo.product_with_roadside_assistance && (
                  <>
                    <div className="memebership-type-container">
                      <Text
                        style={{ margin: "0px", marginRight: "38px" }}
                        variant="bodyHeader"
                      >
                        Medlemskap med veihjelp
                      </Text>
                      <Text
                        style={{ margin: "0px", whiteSpace: "nowrap" }}
                        variant="bodyHeader"
                      >
                        {with_roadside_assistanceNetPrice},-
                      </Text>
                    </div>
                    <div style={{ marginBottom: "7px" }}>
                      <Text style={{ margin: "0px" }} variant="body">
                        {/* Roadside assistance */}
                      </Text>
                    </div>

                    <div className="total">
                      <Text style={{ margin: "0px" }} variant="bodyHeader">
                        Totalt
                      </Text>
                      <Text style={{ margin: "0px" }} variant="subHeader">
                      {with_roadside_assistanceNetPrice},-
                      </Text>
                    </div>
                  </>
                )}
              {invoice.productType ===
                ProductInfo.product_with_out_roadside_assistance && (
                  <>
                    <div className="memebership-type-container">
                      <Text
                        style={{ margin: "0px", marginRight: "38px" }}
                        variant="bodyHeader"
                      >
                        Medlemskap uten veihjelp
                      </Text>
                      <Text
                        style={{ margin: "0px", whiteSpace: "nowrap" }}
                        variant="bodyHeader"
                      >
                        {without_roadside_assistanceNetPrice},-
                      </Text>
                    </div>
                    <div style={{ marginBottom: "7px" }}>
                      <Text style={{ margin: "0px" }} variant="body">
                        {/* Without Roadside assistance */}
                      </Text>
                    </div>

                    <div className="total">
                      <Text style={{ margin: "0px" }} variant="bodyHeader">
                        Totalt
                      </Text>
                      <Text style={{ margin: "0px" }} variant="subHeader">
                      {without_roadside_assistanceNetPrice},-
                      </Text>
                    </div>
                  </>
                )}
            </div>
          </GridCol>
        </GridRow>
      </Grid>
    </Style>
  );
}


Invoice.propTypes = {
  customer: PropTypes.shape({
    basic: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string
    }),
    customerNumber: PropTypes.string
  }),
  invoice: PropTypes.object
};