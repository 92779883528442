import React, { useState, useRef, useEffect } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import { Label, Phone, Message, Input } from "@naf/input";
import { Button } from "@naf/button";
import { Grid, GridRow, GridCol } from "@naf/grid";
import { Text } from "@naf/text";
import Customer from "../../components/Customer";
import Invoice from "../../components/Invoice";
import styled from "styled-components";
import { RadioButton } from "@naf/radio-button";
import Product from "../../components/Product";
import { CustomerService } from "../../services/CustomerService";
import { CustomerSearchService } from "../../services/CustomerSearchService";
import { CommonUtil } from "../../utils/CommonUtils";
import { ProductInfo } from "../../constant/product.constant";
import { DateUtil } from "../../utils/DateUtils";
import { cloneDeep, isEqual } from "lodash";
import PropTypes from "prop-types";

const Style = styled.div`
  .custom-radio-button {
    border: none;
    padding: 0px;
  }
  .step {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    margin-top: 30px;
  }
  .main-header {
    padding-bottom: 45px;
  }
  .search-customer {
    margin-left: 16px;
  }
  .customer-info {
    margin-top: 10px;
  }
  .button-container {
    display: flex;
    gap: 10px;
    margin-top: 50px;
  }
  .step-container {
    margin-top: 65px;
  }
  .plan-detail-container {
    padding-top: 48px;
    padding-bottom: 40px;
  }
  .plan-point {
    align-self: center;
    margin-right: 15px;
  }
  .plan-point-container {
    margin-bottom: 10px;
  }
  .plan-button-container {
    margin-top: 28px;
  }

  .search-container {
    padding-bottom: 0px;
  }
  .feedback {
    margin-top: 40px;
    margin-bottom: 56px;
  }
  .member-info {
    padding-top: 12px;
    padding-left: 12px;
  }
  .RSPBprogressBar {
    margin-top: 16px;
  }
  .pb-1 {
    padding-bottom: 1rem;
  }
  .pl-1 {
    padding-left: 1rem;
  }
  padding-top: 74px;
`;

export default function Membership(props) {
  const [currentStep, setCurrentStep] = useState(1);
  const [customerVisible, setCustomerVisible] = useState(false);
  const [customerMobileNo, setCustomerMobileNo] = useState({
    code: 47,
    number: "",
  });
  const [phoneNumberVisible, setPhoneNumberVisible] = useState(true);
  const [searchBy, setSearchBy] = useState("mobileNo");

  const [customerNumber, setCustomerNumber] = useState("");

  const [initialValueOfCustomer, setInitialValueOfCustomer] = useState({});
  const [cloneAllCustomers, setCloneAllCustomers] = useState(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [phoneNumberRequired, setPhoneNumberRequired] = useState(false);
  const [plan, setPlan] = useState(1);
  const [loading, setLoading] = useState({
    customerSearchLoading: false,
    customerLoading: false,
    productLoading: false,
    invoiceLoading: false,
  });
  const [invoice, setInvoice] = useState(null);
  const [hasActiveSubscription, sethasActiveSubscription] = useState(false);
  const customerFormikRef = useRef(null);
  const customerService = new CustomerService();
  const customerSearchService = new CustomerSearchService();

  const resetData = () => {
    setCurrentStep(1);
    setCustomerVisible(false);
    setInitialValueOfCustomer(null);
    setAllCustomers(null);
    setInvoice(null);
    customerMobileNo.number && setPhoneNumberVisible(false);
    setCustomerMobileNo({ code: 47, number: "" });
    setCustomerNumber("");
  };

  useEffect(() => {
    setPhoneNumberVisible(true);
  }, [phoneNumberVisible]);

  useEffect(() => {
    if (props.reset) {
      resetData();
      props.setReset(false);
    }
  }, [props.reset]);

  const countries = CommonUtil.getCountries();

  function isConsentUpdate(newValue, previousValue) {
    return (
      newValue.useInformationConsent.consented !=
        previousValue.useInformationConsent.consented ||
      newValue.ePost.consented != previousValue.ePost.consented ||
      newValue.sms.consented != previousValue.sms.consented ||
      newValue.agreeAllConsent != previousValue.agreeAllConsent
    );
  }

  function getUpdatedConsents(originalConsents, updatedConsents) {
    const differencesConsents = [];

    updatedConsents.forEach((updatedConsent) => {
      const originalConsent = originalConsents.find(
        (consent) => consent.consentNumber === updatedConsent.consentNumber
      );

      if (
        !originalConsent ||
        originalConsent.consented !== updatedConsent.consented
      ) {
        differencesConsents.push(updatedConsent);
      }
    });
    return differencesConsents;
  }

  function generateConsentRequest(customerId, consentData) {
    return {
      CustomerId: customerId,
      ConsentUpdateRequest: {
        Consents: consentData,
        Source: "Partnerportalen",
      },
    };
  }

  function isProfileUpdate(newValue, previousValue) {
    return (
      !isEqual(newValue.basic, previousValue.basic) ||
      !isEqual(newValue.address, previousValue.address)
    );
  }
  const processCustomer = (customer) => {
    customer.ePost = {
      consentNumber: "30",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "30")
          ?.consented || "false",
    };
    customer.sms = {
      consentNumber: "40",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "40")
          ?.consented || "false",
    };
    customer.useInformationConsent = {
      consentNumber: "10",
      consented:
        customer.consents.find((consent) => consent.consentNumber === "10")
          ?.consented || "false",
    };
    customer.isMember = !!customer.subscriptions?.length;
    if (customer.subscriptions?.length) {
      var currentSubscription = null;
      //take only active subsction out of multiple subscriptions assigned to the user
      currentSubscription = customer.subscriptions.find(
        (subscription) => subscription.status === "active"
      );

      //if active subscription is not there, take last inactive subscription.
      if (
        typeof currentSubscription === "undefined" ||
        currentSubscription === null
      ) {
        currentSubscription =
          customer.subscriptions[customer.subscriptions.length - 1];
      }

      if (currentSubscription.subscriptionLines?.length) {
        const allProductIds = [];
        Object.keys(ProductInfo).forEach((key) =>
          allProductIds.push(ProductInfo[key])
        );

        var hasActiveSubscription = customer.subscriptions.some(
          (subscription) => subscription.status === "active"
        );
        sethasActiveSubscription(hasActiveSubscription);

        const activeSubscriptionLine =
          currentSubscription.subscriptionLines.filter(
            (subscriptionLine) =>
              !subscriptionLine.isExpired &&
              allProductIds.includes(subscriptionLine.productNumber)
          );
        if (activeSubscriptionLine?.length) {
          currentSubscription.subscriptionLines = activeSubscriptionLine;
        }
      }

      customer.productInfo = currentSubscription.subscriptionLines.find(
        (product) =>
          product.productNumber === ProductInfo.product_NAF_young_basis
      );
      if (customer.productInfo) {
        const isProductWithroadSideAssistance =
          currentSubscription.subscriptionLines.find(
            (product) =>
              product.productNumber ===
              ProductInfo.product_with_roadside_assistance
          );
        if (!isProductWithroadSideAssistance) {
          customer.productInfo.name = "NAF Ung uten veihjelp";
        } else {
          customer.productInfo.name = "NAF Ung med veihjelp";
        }
      }
      if (!customer.productInfo) {
        customer.productInfo = currentSubscription.subscriptionLines.find(
          (product) =>
            product.productNumber ===
            ProductInfo.product_with_roadside_assistance
        );

        if (customer.productInfo) {
          customer.productInfo.name = "Medlemskap med veihjelp";
        }
      }
      if (!customer.productInfo) {
        customer.productInfo = currentSubscription.subscriptionLines.find(
          (product) =>
            product.productNumber ===
            ProductInfo.product_with_MC_roadside_assistance
        );
        if (customer.productInfo) {
          customer.productInfo.name = "MC medlemskap med veihjelp";
        }
      }
      if (!customer.productInfo) {
        customer.productInfo = currentSubscription.subscriptionLines.find(
          (product) =>
            product.productNumber ===
              ProductInfo.product_with_out_roadside_assistance ||
            product.productNumber ===
              ProductInfo.product_with_out_roadside_assistance_two
        );
        if (customer.productInfo) {
          customer.productInfo.name = "Medlemskap uten veihjelp";
        }
      }

      if (!customer.productInfo) {
        customer.productInfo = currentSubscription.subscriptionLines.find(
          (product) =>
            product.productNumber === ProductInfo.product_NAF_young ||
            product.productNumber === ProductInfo.product_NAF_young_2
        );
        if (customer.productInfo) {
          customer.productInfo.name = "NAF Ung";
        }
      }

      if (!customer.productInfo) {
        customer.productInfo = currentSubscription.subscriptionLines.find(
          (product) =>
            product.productNumber === ProductInfo.product_NAF_young_basis
        );
        if (customer.productInfo) {
          customer.productInfo.name = "Basis NAF Ung";
        }
      }
    }
    if (customer?.basic?.dateOfBirth) {
      customer.basic.day = DateUtil.getDay(customer?.basic?.dateOfBirth);
      customer.basic.month = DateUtil.getMonth(customer?.basic?.dateOfBirth);
      customer.basic.year = DateUtil.getYear(customer?.basic?.dateOfBirth);
    }
  };

  const searchCustomer = () => {
    if (
      searchBy === "mobileNo" &&
      !CommonUtil.validatePhoneNumber(customerMobileNo.number).valid
    ) {
      setPhoneNumberRequired(true);
      setCustomerVisible(false);
      setInitialValueOfCustomer(null);
      return;
    }

    if (searchBy === "mobileNo") {
      setCustomerNumber("");
    } else if (searchBy === "customerNo") {
      setCustomerMobileNo({
        code: 47,
        number: "",
      });
    }
    setLoading({ ...loading, customerSearchLoading: true });
    customerSearchService
      .searchCustomer(
        searchBy === "mobileNo" ? customerMobileNo.number : null,
        searchBy === "customerNo" ? customerNumber : null
      )
      .then((res) => {
        setLoading({
          ...loading,
          customerSearchLoading: false,
          customerLoading: false,
        });
        if (res.success && res.result?.length) {
          const allCustomers = res.result;
          allCustomers.forEach((customer) => {
            processCustomer(customer);
          });
          const initialCustomer =
            allCustomers && allCustomers.length ? allCustomers[0] : 0;
          setInitialValueOfCustomer(initialCustomer);
          setCloneAllCustomers(cloneDeep(allCustomers));
          setAllCustomers(allCustomers);
        } else {
          setInitialValueOfCustomer(0);
          setAllCustomers([]);
          setCloneAllCustomers([]);
        }
        setPhoneNumberRequired(false);
        setCustomerVisible(true);
      });
  };

  const refreshData = (data) => {
    setTimeout(() => {
      customerSearchService
        .searchCustomer(
          searchBy === "mobileNo" ? customerMobileNo.number : null,
          searchBy === "customerNo" ? customerNumber : null
        )
        .then((res) => {
          setLoading({ ...loading, customerLoading: false });
          if (res.success && res.result?.length) {
            const allCustomers = res.result;
            allCustomers.forEach((customer) => {
              processCustomer(customer);
            });
            const preSelectedCustomer = allCustomers.find(
              (customer) => customer?.customerId === data?.result?.customerId
            );
            setInitialValueOfCustomer(preSelectedCustomer);
            setAllCustomers(allCustomers);
            setCloneAllCustomers(allCustomers);
          }
        });
    }, 500);
  };
  const handleCustomer = () => {
    const errors = customerFormikRef.current.errors;
    if (customerFormikRef.current && Object.keys(errors).length) {
      customerFormikRef.current.setTouched(errors);
      return;
    }
    const value = customerFormikRef.current.values;
    const oldCustomerValue = cloneAllCustomers.find(
      (customer) => customer.customerNumber === value.customerNumber
    );

    if (
      oldCustomerValue &&
      value.customerNumber &&
      isEqual(oldCustomerValue, value)
    ) {
      setCurrentStep(2);
      return;
    }
    value.consents = props?.isConsentRequired
      ? [value.ePost, value.sms, value.useInformationConsent]
      : null;
    value.basic.dateOfBirth = DateUtil.convertToStringDate(
      value.basic.day,
      value.basic.month,
      value.basic.year
    );

    setLoading({ ...loading, customerLoading: true });
    if (value.customerNumber) {
      if (
        (isConsentUpdate(value, initialValueOfCustomer) &&
          isProfileUpdate(value, initialValueOfCustomer)) ||
        isProfileUpdate(value, initialValueOfCustomer)
      ) {
        customerService.updateCustomer({ customer: value }).then(
          (data) => {
            if (data.success) {
              setCurrentStep(2);
              refreshData(data);
            }
          },
          () => setLoading({ ...loading, customerLoading: false })
        );
      } else {
        let updatedConsents = getUpdatedConsents(
          initialValueOfCustomer.consents,
          value.consents
        );
        const formattedConsentData = updatedConsents.map((consent) => ({
          Consented: consent.consented,
          ConsentId: consent.consentNumber,
        }));
        const consentRequests = generateConsentRequest(
          value.customerId,
          formattedConsentData
        );

        customerService.updateConsent(consentRequests).then(
          (data) => {
            if (data.success) {
              setCurrentStep(2);
              refreshData(data);
            }
          },
          () => setLoading({ ...loading, customerLoading: false })
        );
      }
    } else {
      customerService.createCustomer({ customer: value }).then(
        (data) => {
          if (data.success) {
            setInitialValueOfCustomer(processCustomer(data.result));
            setCurrentStep(2);
            refreshData(data);
          } else {
            setLoading({ ...loading, customerLoading: false });
          }
        },
        () => setLoading({ ...loading, customerLoading: false })
      );
    }
  };

  return (
    <Style>
      <Text
        style={{
          margin: 0,
        }}
        tag="h1"
        variant="header1"
        className="main-header"
      >
        Partnerportalen
      </Text>
      <Text
        style={{
          margin: 0,
        }}
        variant="small"
      >
        Steg {currentStep} av 3
      </Text>

      <div>
        <Grid>
          <GridRow columns="12">
            <GridCol m="4" l="4" xl="4">
              <Text
                style={{
                  margin: 0,
                }}
                variant={currentStep === 1 ? "bodyTextSubHeader" : "bodyText"}
              >
                Kunde
              </Text>
              <ProgressBar
                percent={100}
                filledBackground="rgb(123, 219, 131)"
              />
            </GridCol>
            <GridCol m="4" l="4" xl="4">
              <Text
                style={{
                  margin: 0,
                }}
                variant={currentStep === 2 ? "bodyTextSubHeader" : "bodyText"}
              >
                Medlemskap
              </Text>
              <ProgressBar
                percent={currentStep >= 2 ? 100 : 0}
                filledBackground="rgb(123, 219, 131)"
              />
            </GridCol>
            <GridCol m="4" l="4" xl="4">
              <Text
                style={{
                  margin: 0,
                }}
                variant={currentStep === 3 ? "bodyTextSubHeader" : "bodyText"}
              >
                Bekreftelse
              </Text>
              <ProgressBar
                percent={currentStep >= 3 ? 100 : 0}
                filledBackground="rgb(123, 219, 131)"
              />
            </GridCol>
          </GridRow>
        </Grid>

        <div>
          <Grid className="step-container">
            {currentStep === 1 && (
              <>
                <Text
                  tag="h2"
                  style={{
                    marginBottom: "35px",
                  }}
                  variant="header2"
                >
                  Finn NAF medlem/kunde
                </Text>
                <GridRow columns="12">
                  <GridCol m="6" l="6" xl="6" className="search-container">
                    <Label variant="LabelSmall">Kunden sitt</Label>
                    <div className="d-flex pb-1">
                      <RadioButton
                        label="mobilnummer"
                        value="mobileNo"
                        onClick={(val) => setSearchBy("mobileNo")}
                        name="searchByMobile"
                        checked={searchBy === "mobileNo"}
                      />
                      <RadioButton
                        className="pl-1"
                        label="Kundenummer"
                        onClick={(val) => setSearchBy("customerNo")}
                        name="searchByCustomer"
                        value="customerNo"
                        checked={searchBy === "customerNo"}
                      />
                    </div>
                    <div className="d-flex">
                      {phoneNumberVisible && searchBy === "mobileNo" && (
                        <Phone
                          onChange={(nr) => {
                            setCustomerMobileNo(nr);
                          }}
                          placeholder="8 siffer"
                          pattern="[0-9]+"
                          minlength="8"
                          maxlenght="8"
                          countries={countries}
                          value={customerMobileNo.number}
                          selectedCountry={countries[3]}
                          error={
                            phoneNumberRequired &&
                            !CommonUtil.validatePhoneNumber(
                              customerMobileNo.number
                            ).valid
                          }
                        />
                      )}
                      {phoneNumberVisible && searchBy === "customerNo" && (
                        <Input
                          onChange={(value) => setCustomerNumber(value)}
                          value={customerNumber || ""}
                          name="customerNumber"
                        />
                      )}

                      <Button
                        onClick={searchCustomer}
                        text="Handling"
                        variant="secondary"
                        isLoading={loading.customerSearchLoading}
                        type="button"
                        className="search-customer"
                      >
                        Hent opplysninger
                      </Button>
                    </div>
                    {phoneNumberRequired &&
                      !CommonUtil.validatePhoneNumber(customerMobileNo.number)
                        .valid && (
                        <Message error>
                          {
                            CommonUtil.validatePhoneNumber(
                              customerMobileNo.number
                            ).message
                          }
                        </Message>
                      )}
                  </GridCol>
                </GridRow>
              </>
            )}

            {currentStep === 1 && customerVisible && (
              <>
                <GridRow columns="12">
                  <GridCol m="6" l="6" xl="6">
                    <div className="select-customer-container">
                      <Text
                        tag="h2"
                        style={{
                          marginTop: "38px",
                          marginBottom: "24px",
                        }}
                        variant="header2"
                      >
                        Velg NAF medlem/kunde
                      </Text>

                      {allCustomers.map((customer) => (
                        <>
                          {!customer.isMember && (
                            <div className="customer-info">
                              <RadioButton
                                label={`${customer?.basic?.firstName} ${
                                  customer?.basic?.lastName
                                } ${
                                  customer?.address?.city
                                    ? "- " + customer?.address?.city
                                    : ""
                                } `}
                                name="customer"
                                value={customer}
                                checked={initialValueOfCustomer === customer}
                                width="full"
                                onChange={() => {
                                  setInitialValueOfCustomer(customer);
                                }}
                                outline
                              />
                            </div>
                          )}
                          {customer.isMember && (
                            <div className="customer-info">
                              <div
                                className="member-info border"
                                onClick={() => {
                                  setInitialValueOfCustomer(customer);
                                }}
                              >
                                <RadioButton
                                  label={`${customer?.basic?.firstName} ${
                                    customer?.basic?.lastName
                                  } 
                                  ${
                                    customer?.address?.city
                                      ? "- " + customer?.address?.city
                                      : ""
                                  } 
                                 `}
                                  message={customer?.customerNumber}
                                  name="customer"
                                  value={customer}
                                  checked={initialValueOfCustomer === customer}
                                  width="full"
                                  outline
                                  className="custom-radio-button"
                                />

                                <Text
                                  tag="h3"
                                  style={{
                                    marginBottom: "0px",
                                    marginTop: "25px",
                                    marginLeft: "32px",
                                  }}
                                  variant="header3"
                                >
                                  {customer?.productInfo?.name}
                                </Text>

                                <div className="d-flex">
                                  <Text
                                    style={{
                                      marginTop: "0px",
                                      marginRight: "20px",
                                      marginLeft: "32px",
                                    }}
                                    variant="details"
                                  >
                                    Medlem siden:{" "}
                                    {DateUtil.convertToDisplayFormat(
                                      customer?.productInfo?.signUpDate
                                    )}
                                  </Text>
                                  {!hasActiveSubscription && (
                                    <Text
                                      style={{
                                        marginTop: "0px",
                                        marginRight: "22px",
                                        marginLeft: "32px",
                                      }}
                                      variant="details"
                                      className={
                                        customer?.productInfo?.isExpired
                                          ? "text-danger"
                                          : ""
                                      }
                                    >
                                      {!customer?.productInfo?.isExpired
                                        ? "Gyldig til:"
                                        : " Utløpt:"}{" "}
                                      {DateUtil.convertToDisplayFormat(
                                        customer?.productInfo?.validUntilDate
                                      )}
                                    </Text>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                      <div
                        className="customer-info"
                        onClick={() => setInitialValueOfCustomer(null)}
                      >
                        <RadioButton
                          label="Opprett nytt medlem"
                          name="customer"
                          value={0}
                          width="full"
                          checked={!initialValueOfCustomer}
                          outline
                        />
                      </div>
                    </div>

                    <Customer
                      isConsentRequired={props?.isConsentRequired}
                      mobilePhone={customerMobileNo.number}
                      ref={customerFormikRef}
                      initialValueOfCustomer={initialValueOfCustomer}
                    />
                    <div className="button-container">
                      <Button onClick={resetData} variant="outline">
                        Tilbake
                      </Button>
                      {((initialValueOfCustomer &&
                        !initialValueOfCustomer.hasActiveMembership) ||
                        !initialValueOfCustomer) && (
                        <Button
                          onClick={handleCustomer}
                          isLoading={loading.customerLoading}
                        >
                          Neste
                        </Button>
                      )}
                    </div>
                  </GridCol>
                </GridRow>
              </>
            )}
            {currentStep === 2 && (
              <>
                <Product
                  customer={initialValueOfCustomer}
                  step={currentStep}
                  setStep={setCurrentStep}
                  plan={plan}
                  setInvoice={setInvoice}
                  setPlan={setPlan}
                />
                <GridRow>
                  <GridCol
                    m="12"
                    s="12"
                    l="12"
                    xl="12"
                    className="w-100 d-flex col-gap plan-button-container"
                  >
                    <Button
                      onClick={() => setCurrentStep(1)}
                      variant="outline"
                      size="small"
                      className="p-1"
                      text="Tilbake"
                    ></Button>
                  </GridCol>
                </GridRow>
              </>
            )}
            {currentStep === 3 && (
              <div style={{ marginTop: "-39px" }}>
                <Invoice customer={initialValueOfCustomer} invoice={invoice} />
              </div>
            )}
          </Grid>
        </div>
      </div>
    </Style>
  );
}

Membership.propTypes = {
  isConsentRequired: PropTypes.bool,
  reset: PropTypes.bool,
  setReset: PropTypes.func,
};
