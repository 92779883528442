import React, { useState, useEffect } from "react";
import with_roadside_assistance from "../assets/images/with_roadside_assistance.png";
import without_roadside_assistance from "../assets/images/without_roadside_assistance.png";
import check from "../assets/images/check.svg";
import cross from "../assets/images/cross.svg";
import { GridRow, GridCol, Grid} from "@naf/grid";
import { Text } from "@naf/text";
import styled from "styled-components";
import { ProductService } from "../services/ProductService";
import { Button } from "@naf/button";
import {ProductInfo} from "../constant/product.constant";
import { useAuth0 } from "@auth0/auth0-react";
import { ProductPriceService } from "../services/ProductPriceService";
import { Tooltip } from "@naf/parts";
import { RadioButton } from "@naf/radio-button";
import { Label } from "@naf/input";
import PropTypes from 'prop-types';

const Style = styled.div`
  .product {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 54px;
    padding-left: 64px;
    padding-bottom: 64px;
  } 
  .grid-gap-25 {
    grid-gap: 25%;
}
`;

export default function Product(props) {
  const { customer, setInvoice } = props;
  const { user } = useAuth0();
  const [step, setStep] = [props.step, props.setStep];
  const productService = new ProductService();
  const [loadingProductWithRoadSide, setLoadingProductWithRoadSide] =
    useState(false);
  const [loadingProductWithoutRoadSide, setLoadingProductWithoutRoadSide] =
    useState(false);
  const [memberhsipWithRoadAssist, setMemberhsipWithRoadAssist] =
    useState(null);
  const [memberWithoutRoadAssist, setMemberWithoutRoadAssist] = useState(null);
  const [priceProductLoading, setPriceProductLoading] = useState(false);
  const [
    publicationFormatwithoutRoadSide,
    setPublicationFormatwithoutRoadSide,
  ] = useState("digital");
  const [publicationFormatwithRoadSide, setPublicationFormatwithRoadSide] =
    useState("digital");

  const handleChangeWithOutRoadSide = (event) => {
    setPublicationFormatwithoutRoadSide(event.target.value);
  };

  const handleChangeWithRoadSide = (event) => {
    setPublicationFormatwithRoadSide(event.target.value);
  };

  const confirmOrder = (productType) => {
    const payload = {
      CustomerId: customer.customerId,
      CustomerNumber: customer.customerNumber,
      SalesOrder: {
        Status: "submitted",
        SalesAgent: user.email,
        SalesLocationNumber: "90407446",
        SalesChannel: "web",
        PaymentInterval: "yearly",
        SalesOrderLines: [],
      },
    };
    if (productType === ProductInfo.product_with_roadside_assistance) {
      setLoadingProductWithRoadSide(true);
      payload.SalesOrder.SalesOrderLines = [
        memberWithoutRoadAssist,
        memberhsipWithRoadAssist,
      ];
      payload.SalesOrder.publicationFormat = publicationFormatwithRoadSide;
    } else if (
      productType === ProductInfo.product_with_out_roadside_assistance
    ) {
      setLoadingProductWithoutRoadSide(true);
      payload.SalesOrder.SalesOrderLines = [memberWithoutRoadAssist];
      payload.SalesOrder.publicationFormat = publicationFormatwithoutRoadSide;
    }
    if (payload?.SalesOrder?.SalesOrderLines?.length) {
      payload.SalesOrder.SalesOrderLines.forEach((line) => {
        line.productUserCustomerId = customer.customerId;
      });
    }
    productService.createSalesOrder(payload).then((data) => {
      setLoadingProductWithRoadSide(false);
      setLoadingProductWithoutRoadSide(false);
      if (data.success) {
          if (data.result?.invoice) {
            setInvoice({ ...data.result.invoice, productType, order: payload });
            setStep(3);
        } else if (data.hasUnpaidInvoice) {
            setInvoice({ hasUnpaidInvoice: true, productType, order: payload });   
            setStep(3);
        }
      }
    });
  };

  useEffect(() => {
    const productPriceService = new ProductPriceService();
    setPriceProductLoading(true);
    productPriceService
      .getProductPriceDetails()
      .then((res) => {
        setPriceProductLoading(false);
        if (res?.products?.length) {
          setMemberWithoutRoadAssist(
            res?.products.find((prod) => prod?.productNumber === "1121")
          );
          setMemberhsipWithRoadAssist(
            res?.products.find((prod) => prod?.productNumber === "2020")
          );
        }
      })
      .catch((err) => setPriceProductLoading(false));
  }, []);

  return (
    <Style>
      <div>
        <Text
          tag="h2"
          style={{
            marginBottom: "32px",
          }}
          variant="header2"
        >
          Velg medlemskap
        </Text>       
        <Grid>
        <GridRow className="grid-gap-25">
          <GridCol m="6" l="6" xl="6" s="6" >
            <div className="product border">
              <div className="d-flex w-100 col-gap align-items-center">
                <Text
                  tag="h3"
                  style={{
                    margin: 0,
                  }}
                  variant="header3"
                >
                  Medlemskap med veihjelp
                </Text>
              </div>

              <div className="icon">
                <img alt="road-side-assitance" src={with_roadside_assistance} />
              </div>
              <div className="plan-detail-container">
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Veihjelp i Norge og Europa
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Bilnøkkelforsikring
                  </Text>
                </div>
                <div className="d-flex plan-point-container">                
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Juridisk rådgivning
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Egenandelsforsikring for leiebil
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Veibok og Motor
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Rabatt på EU-kontroll, tester og service
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Gunstig billån og forsikring
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Ladebrikke og ladefordeler
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Omfattende fordelsprogram
                  </Text>
                </div>
              </div>
              <div className="d-flex   flex-direction-column">
                {!priceProductLoading && (
                  <Text style={{ margin: "0px" }} tag="h3" variant="header3">
                    {memberWithoutRoadAssist?.netPrice +
                      memberhsipWithRoadAssist?.netPrice}{" "}
                    kr
                  </Text>
                )}
                {priceProductLoading && (
                  <Button
                    variant="primary"
                    size="small"
                    type="button"
                    style={{ marginTop: "40px" }}
                    isLoading={true}
                  ></Button>
                )}
                <Text style={{ margin: "0px" }} tag="p" variant="body">
                  for 12 måneder
                </Text>
              </div>

              <div
                className="d-flex plan-point-container"
                style={{
                  marginTop: "15px",
                }}
              >
                <GridCol m="12" l="12" xl="12">
                  <div
                    className="member-info border"
                    style={{ padding: "0.6em" }}
                  >
                    <Label
                      style={{ margin: "0px", marginBottom: "15px" }}
                      tag="h3"
                      variant="header3"
                    >
                      Tilleggsvalg{" "}
                    </Label>
                    <div
                      className="member-info border"
                      style={{ padding: "0.6em" }}
                    >
                      <Label
                        style={{
                          margin: "0px",
                          marginTop: 0,
                          fontWeight: "bold",
                        }}
                        tag="h4"
                        variant="header4"
                      >
                        NAF Veibok{" "}
                        <Tooltip text="Dette er en kort og informativ hjelpetekst" />
                      </Label>

                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "15px" }}
                      >
                        <RadioButton
                          label="Ja, jeg vi ha veibok i posten."
                          value="paper"
                          onClick={handleChangeWithRoadSide}
                          checked={publicationFormatwithRoadSide === "paper"}
                          name="publicationFormat"
                        />
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "10px" }}
                      >
                        <RadioButton
                          label="Nei, jeg vil ikke ha veibok i posten."
                          value="digital"
                          onClick={handleChangeWithRoadSide}
                          checked={publicationFormatwithRoadSide === "digital"}
                          name="publicationFormat"
                        />
                      </div>
                    </div>
                  </div>
                </GridCol>
              </div>

              <Button               
                style={{ marginTop: "40px" }}
                isLoading={loadingProductWithRoadSide}
                disabled={loadingProductWithoutRoadSide}
                onClick={() =>
                  confirmOrder(ProductInfo.product_with_roadside_assistance)
                }
              >Velg</Button>
            </div>
          </GridCol>
          <GridCol m="6" l="6" xl="6" s="6">
            <div className="product border">
              <div className="d-flex w-100 col-gap align-items-center">
                <Text
                  tag="h3"
                  style={{
                    margin: 0,
                  }}
                  variant="header3"
                >
                  Medlemskap uten veihjelp
                </Text>
              </div>

              <div className="icon">
                <img
                  alt="road-side-assitance"
                  src={without_roadside_assistance}
                />
              </div>
              <div className="plan-detail-container">
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={cross} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Veihjelp i Norge og Europa
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={cross} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Bilnøkkelforsikring
                  </Text>
                </div>

                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Juridisk rådgivning
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={cross} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Egenandelsforsikring for leiebil
                  </Text>
                </div>

                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Veibok og Motor
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Rabatt på EU-kontroll, tester og service
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Gunstig billån og forsikring
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Ladebrikke og ladefordeler
                  </Text>
                </div>
                <div className="d-flex plan-point-container">
                  <img alt="check" className="plan-point" src={check} />
                  <Text
                    style={{
                      margin: 0,
                    }}
                    variant="body"
                  >
                    Omfattende fordelsprogram
                  </Text>
                </div>
              </div>
              <div className="d-flex   flex-direction-column">
                {!priceProductLoading && (
                  <Text style={{ margin: "0px" }} tag="h3" variant="header3">
                    {memberWithoutRoadAssist?.netPrice} kr
                  </Text>
                )}

                {priceProductLoading && (
                  <Button
                    variant="primary"
                    size="small"
                    type="button"
                    style={{ marginTop: "40px" }}
                    isLoading={true}
                  ></Button>
                )}

                <Text style={{ margin: "0px" }} tag="p" variant="body">
                  for 12 måneder
                </Text>
              </div>

              <div
                className="d-flex plan-point-container"
                style={{
                  marginTop: "15px",
                }}
              >
                <GridCol m="12" l="12" xl="12">
                  <div
                    className="member-info border"
                    style={{ padding: "0.6em" }}
                  >
                    <Label
                      style={{ margin: "0px", marginBottom: "15px" }}
                      tag="h3"
                      variant="header3"
                    >
                      Tilleggsvalg{" "}
                    </Label>
                    <div
                      className="member-info border"
                      style={{ padding: "0.6em" }}
                    >
                      <Label
                        style={{
                          margin: "0px",
                          marginTop: 0,
                          fontWeight: "bold",
                        }}
                        tag="h4"
                        variant="header4"
                      >
                        NAF Veibok{" "}
                        <Tooltip text="Dette er en kort og informativ hjelpetekst" />
                      </Label>

                      <div
                        className="d-flex justify-content-between"
                        style={{ marginTop: "15px" }}
                      >
                        <RadioButton
                          label="Ja, jeg vi ha veibok i posten."
                          value="paper"
                          onClick={handleChangeWithOutRoadSide}
                          checked={publicationFormatwithoutRoadSide === "paper"}
                          name="publicationFormatWithOutRoadSide"
                        />
                      </div>
                      <div
                        className="d-flex justify-content-between."
                        style={{ marginTop: "10px" }}
                      >
                        <RadioButton
                          label="Nei, jeg vil ikke ha veibok i posten."
                          value="digital"
                          onClick={handleChangeWithOutRoadSide}
                          checked={
                            publicationFormatwithoutRoadSide === "digital"
                          }
                          name="publicationFormatWithOutRoadSide"
                        />
                      </div>
                    </div>
                  </div>
                </GridCol>
              </div>

              <Button             
                style={{ marginTop: "40px" }}
                isLoading={loadingProductWithoutRoadSide}
                disabled={loadingProductWithRoadSide}
                onClick={() =>
                  confirmOrder(ProductInfo.product_with_out_roadside_assistance)
                }               
              >Velg</Button>
            </div>
          </GridCol>
          </GridRow>
        </Grid>       
      </div>
    </Style>
  );
}

Product.propTypes = {
  customer: PropTypes.shape({
    customerId: PropTypes.string.isRequired,
    customerNumber: PropTypes.string.isRequired
    
  }).isRequired,
  setInvoice: PropTypes.func.isRequired,
};
