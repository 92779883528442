import axios from "axios";
export class CustomerSearchService {
  service = "customer";



  searchCustomer(phone, customerNumber) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/searchcustomersbyphone`;
    return axios.get(url, { params: { phone, customerNumber } });

  }
}
