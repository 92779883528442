import { Button } from "@naf/button";
import { Text } from "@naf/text";
import React from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, GridRow, GridCol } from "@naf/grid";
import membership from "../../assets/images/membership.svg";
import { TextList, TextListItem } from "@naf/text-list";
import { Accordion } from "@naf/accordion";

const Style = styled.div`
  padding-top: 64px;
  margin-bottom: -10px;
  .header-details {
    margin-top: 16px;
    margin-bottom: 32px;
  }
  .step-container {
    margin-top: 8px;
    margin-bottom: 40px;
  }
  .step-text-list {
    padding-left: 19px;
  }
  .questions-container {
    margin-top: 55px;
    margin-left: -13vw;
    margin-right: -13vw;
    padding-top: 103px;
    padding-bottom: 103px;
    padding-left: 160px;
    background: #e9f3e6;
    left: 0;
  }
`;
export default function Home() {
  const { loginWithRedirect } = useAuth0();

  return (
    <Style>
      <Text
        style={{
          margin: 0,
        }}
        variant="display"
      >
        Velkommen til partnerportalen
      </Text>
      <Grid>
        <GridRow columns="12">
          <GridCol m="6" l="6" xl="6" s="12">
            <div className="header-details">
              <Text
                style={{
                  margin: 0,
                }}
                variant="ingress"
              >
                Her kan du opprette medlemskap i NAF og søke etter eksisterende
                NAF-medlemmer.
              </Text>
            </div>
            <Text
              style={{
                margin: 0,
              }}
              variant="header3"
            >
              Slik gjør du:
            </Text>
            <div className="step-container">
              <TextList className="step-text-list" variant="number">
                <TextListItem>
                  Logg inn med e-postadressen og passordet for din butikk
                </TextListItem>
                <TextListItem>Sjekk om kunden er NAF-medlem</TextListItem>
                <TextListItem>
                  Opprett medlemskap med eller uten veihjelp
                </TextListItem>
                <TextListItem>
                  Nye medlemmer blir kontaktet på e-post. De kan opprette bruker
                  på Min side med e-posten sin. Medlemskapet er gyldig med en
                  gang bestillingen er gjennomført.
                </TextListItem>
              </TextList>
            </div>
            <Button size="small" onClick={loginWithRedirect}>
              Logg inn
            </Button>
          </GridCol>
          <GridCol m="6" l="6" xl="6" s="12">
            <div className="d-flex justify-content-end">
              <img
                style={{ alignSelf: "start" }}
                alt="member"
                src={membership}
              />
            </div>
          </GridCol>
        </GridRow>
      </Grid>
      <div className="questions-container">
        <Text
          style={{
            margin: 0,
            paddingBottom: "24px",
          }}
          variant="header2"
        >
          Spørsmål og svar
        </Text>

        <Accordion
          label="Jeg har glemt passordet mitt, hva gjør jeg?"
          maxWidth={735}
          size="medium"
        >
          Trykk på Logg inn og velg glemt passord. Du får en lenke på e-posten
          for å tilbakestille passordet.
        </Accordion>

        <Accordion
          label="Hva er e-posten jeg skal logge på med?"
          maxWidth={735}
          size="medium"
        >
          Sjekk om dere har en felles e-post for bedriften. Ta kontakt med NAF
          for å opprette ny bruker.
        </Accordion>
      </div>
    </Style>
  );
}
