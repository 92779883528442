import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Grid, GridRow, GridCol } from "@naf/grid";
import {
  Input,
  Label,
  Message,
  DateField,
  DateWrapper,
  Month,
  Day,
  Year,
  Phone,
} from "@naf/input";
import { RadioButton } from "@naf/radio-button";
import * as Yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import { Text } from "@naf/text";
import { Checkbox } from "@naf/checkbox";
import { CommonUtil } from "../utils/CommonUtils";
import {PropTypes} from 'prop-types'; // Import PropTypes

const Style = styled.div`
  padding-top: 80px;
  .all-terms-container {
    display: flex;
    width: 100%;
    gap: 55px;
    margin-top: 23px;
  }
  .radio-gap {
    display: flex;
    width: 100%;
    gap: 20px;
  }
`;
const Customer = forwardRef((props, ref) => {
  const [phoneNumberVisible, setPhoneNumberVisible] = useState(true);
  const [showConsents, setShowConsents] = useState(true);
  const formik = useFormik({
    validateOnMount: true,
    initialValues: props.initialValueOfCustomer
      ? props.initialValueOfCustomer
      : {
          hasActiveMembership: false,
          customerNumber: "",
          customerId: "",
          basic: {
            firstName: "",
            lastName: "",
            email: "",
            day: "",
            month: "",
            year: "",
            gender: "male",           
            mobilePhone: props.mobilePhone,
          },
          ePost: {
            consentNumber: "30",
            consented: "true",
          },
          sms: {
            consentNumber: "40",
            consented: "true",
          },
          useInformationConsent: {
            consentNumber: "10",
            consented: "true",
          },
          address: {
            streetLine1: "",
            streetLine2: "",
            postalCode: "",
            city: "",
            country: "",
          },
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      basic: Yup.object().shape({
        mobilePhone: Yup.string()
          .required("Vennligst fyll ut Mobilnummer")
          .nullable()
          .test({
            test: (value) => {
              return !isNaN(parseFloat(value)) && value?.length === 8;
            },
            message: "Dette er ikke en gyldig mobilnummer",
          }),
        firstName: Yup.string()
          .nullable()
          .required("Vennligst fyll ut Fornavn"),
        lastName: Yup.string()
          .nullable()
          .required("Vennligst fyll ut Etternavn"),
        email: Yup.string()
          .nullable()
          .email("Dette er ikke en gyldig e-post")
          .required("Vennligst fyll ut E-post"),
        day: Yup.string()
          .required("Bruk tall")
          .nullable()
          .test({
            test: (value) => {
              return !isNaN(parseFloat(value));
            },
            message: "Bruk tall",
          }),
        month: Yup.string()
          .required("Bruk tall")
          .nullable()
          .test({
            test: (value) => {
              return !isNaN(parseFloat(value));
            },
            message: "Bruk tall",
          }),
        year: Yup.string()
          .required("Bruk tall")
          .nullable()
          .test({
            test: (value) => {
              return (
                new Date().getFullYear() - value >= 18 &&
                !isNaN(parseFloat(value))
              );
            },
            message: "Du må være over 18 år",
          }),
        gender: Yup.string(),
      }),
      address: Yup.object().shape({
        streetLine1: Yup.string()
          .nullable()
          .required("Vennligst fyll ut Addresse"),
        postalCode: Yup.string()
          .nullable()
          .required("Vennligst fyll ut Postnummer")
          .length(4, "Dette er ikke et gyldig postnummer"),
        city: Yup.string().nullable().required("Vennligst fyll ut Poststed"),
      }),
    }),
  });
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setTouched,
    setFieldValue,
  } = formik;
  const { initialValueOfCustomer } = props;
  const countries = CommonUtil.getCountries();

  useEffect(() => {
    setShowConsents(true);
  }, [showConsents]);

  useEffect(() => {
    resetForm();
    setPhoneNumberVisible(false);
  }, [initialValueOfCustomer, resetForm]);

  useEffect(() => {
    setPhoneNumberVisible(true);
  }, [phoneNumberVisible]);

  useImperativeHandle(ref, () => ({
    values,
    errors,
    setTouched,
  }));

  useEffect(() => {
    resetForm();
  }, []);
  if (!values) {
    return <></>;
  }
  return (
    <Style>
      <Text
        tag="h2"
        style={{
          marginTop: "0px",
          marginBottom: "40px",
        }}
        variant="header2"
      >
        Medlemsinformasjon
      </Text>

      <form onSubmit={handleSubmit}>
        <Grid>
          <GridRow columns="12">
            {values.customerNumber && (
              <>
                <GridCol m="6" l="6" xl="6">
                  <Label>Mobilnummer</Label>
                  {phoneNumberVisible && (
                    <Phone
                      placeholder="8 siffer"
                      pattern="[0-9]+"
                      name="basic.mobilePhone"
                      countries={countries}
                      onChange={(value) =>
                        setFieldValue("basic.mobilePhone", value.number)
                      }
                      onBlur={handleBlur}
                      value={values.basic?.mobilePhone || ""}
                      selectedCountry={countries[3]}
                      disabled={values.hasActiveMembership}
                      error={
                        errors?.basic?.mobilePhone && touched.basic?.mobilePhone
                      }
                    />
                  )}

                  {errors?.basic?.mobilePhone && touched.basic?.mobilePhone && (
                    <Message error> {errors?.basic?.mobilePhone}</Message>
                  )}
                </GridCol>
                <GridCol m="6" l="6" xl="6">
                  {values.hasActiveMembership && (
                    <>
                      <Label>Kundenummer</Label>
                      <Input
                        onInput={handleChange}
                        onBlur={handleBlur}
                        value={values.customerNumber || ""}
                        name="customerNumber"
                        disabled={true}
                        error={errors?.customerNumber && touched.customerNumber}
                      />
                      {errors?.customerNumber && touched.customerNumber && (
                        <Message error> {errors?.customerNumber}</Message>
                      )}
                    </>
                  )}
                </GridCol>
              </>
            )}
            <GridCol m="6" l="6" xl="6">
              <Label>Fornavn og mellomnavn</Label>
              <Input
                onInput={handleChange}
                onBlur={handleBlur}
                value={values?.basic?.firstName || ""}
                name="basic.firstName"
                disabled={values.hasActiveMembership}
                error={errors?.basic?.firstName && touched?.basic?.firstName}
              />
              {errors?.basic?.firstName && touched?.basic?.firstName && (
                <Message error> {errors?.basic?.firstName}</Message>
              )}
            </GridCol>
            <GridCol m="6" l="6" xl="6">
              <Label>Etternavn</Label>
              <Input
                onInput={handleChange}
                onBlur={handleBlur}
                value={values.basic.lastName || ""}
                name="basic.lastName"
                disabled={values.hasActiveMembership}
                error={errors?.basic?.lastName && touched?.basic?.lastName}
              />
              {errors?.basic?.lastName && touched?.basic?.lastName && (
                <Message error> {errors?.basic?.lastName}</Message>
              )}
            </GridCol>

            <GridCol m="12" l="12" xl="12">
              <Label>E-post</Label>
              <Input
                onInput={handleChange}
                onBlur={handleBlur}
                value={values.basic?.email || ""}
                name="basic.email"
                width="100%"
                disabled={values.hasActiveMembership}
                error={errors?.basic?.email && touched?.basic?.email}
              />
              {errors?.basic?.email && touched?.basic?.email && (
                <Message error> {errors?.basic?.email}</Message>
              )}
            </GridCol>

            <GridCol m="12" l="12" xl="12">
              <Label toolTip="Used to give age discount">Fødselsdato</Label>
              <DateWrapper>
                <DateField>
                  <Day
                    id="day"
                    onInput={handleChange}
                    onBlur={handleBlur}
                    value={values.basic.day || ""}
                    name="basic.day"
                    nextId="month1"
                    disabled={values.hasActiveMembership}
                    error={errors?.basic?.day && touched?.basic?.day}
                  />
                  {errors?.basic?.day && touched?.basic?.day && (
                    <Message error> {errors?.basic?.day}</Message>
                  )}
                </DateField>
                <DateField>
                  <Month
                    id="month1"
                    nextId="year1"
                    onInput={handleChange}
                    onBlur={handleBlur}
                    value={values.basic.month || ""}
                    name="basic.month"
                    disabled={values.hasActiveMembership}
                    error={errors?.basic?.month && touched?.basic?.month}
                  />
                  {errors?.basic?.month && touched?.basic?.month && (
                    <Message error> {errors?.basic?.month}</Message>
                  )}
                </DateField>
                <DateField>
                  <Year
                    id="year1"
                    placeholder="yyyy"
                    onInput={handleChange}
                    onBlur={handleBlur}
                    value={values.basic.year || ""}
                    name="basic.year"
                    disabled={values.hasActiveMembership}
                    error={errors?.basic?.year && touched?.basic?.year}
                  />
                  {errors?.basic?.year && touched?.basic?.year && (
                    <Message error> {errors?.basic?.year}</Message>
                  )}
                </DateField>
              </DateWrapper>
            </GridCol>

            <GridCol m="12" l="12" xl="12">
              <Label>Kjønn </Label>
              <div className="d-flex justify-content-between">
                <RadioButton
                  label="Kvinne"
                  value="female"
                  onClick={handleChange}
                  disabled={values.hasActiveMembership}
                  name="basic.gender"
                  checked={values.basic.gender === "female"}
                />
                <RadioButton
                  label="Mann"
                  onClick={handleChange}
                  name="basic.gender"
                  disabled={values.hasActiveMembership}
                  value="male"
                  checked={values.basic.gender === "male"}
                />
                <RadioButton
                  label="Vil ikke oppgi kjønn"
                  onClick={handleChange}
                  name="basic.gender"
                  disabled={values.hasActiveMembership}
                  value="unknown"
                  checked={values.basic.gender === "unknown"}
                />
              </div>
            </GridCol>

            <GridCol m="12" l="12" xl="12">
              <Label>Addresse</Label>
              <Input
                onInput={handleChange}
                onBlur={handleBlur}
                value={values.address?.streetLine1}
                width="100%"
                name="address.streetLine1"
                disabled={values.hasActiveMembership}
                error={
                  errors?.address?.streetLine1 && touched.address?.streetLine1
                }
              />
              {errors?.address?.streetLine1 && touched.address?.streetLine1 && (
                <Message error> {errors?.address?.streetLine1}</Message>
              )}
            </GridCol>

            <GridCol m="6" l="6" xl="6">
              <Label>Postnummer</Label>
              <Input
                onInput={handleChange}
                onBlur={handleBlur}
                value={values.address.postalCode || ""}
                disabled={values.hasActiveMembership}
                name="address.postalCode"
                error={
                  errors?.address?.postalCode && touched.address?.postalCode
                }
              />
              {errors?.address?.postalCode && touched?.address?.postalCode && (
                <Message error> {errors?.address?.postalCode}</Message>
              )}
            </GridCol>
            <GridCol m="6" l="6" xl="6">
              <Label>Poststed</Label>
              <Input
                onInput={handleChange}
                onBlur={handleBlur}
                value={values.address.city || ""}
                disabled={values.hasActiveMembership}
                name="address.city"
                error={errors?.address?.city && touched.address?.city}
              />
              {errors?.address?.city && touched.address?.city && (
                <Message error> {errors?.address?.city}</Message>
              )}
            </GridCol>
            {props?.isConsentRequired &&
            <GridCol m="12" l="12" xl="12">
              <Text
                style={{
                  marginTop: "28px",
                  marginBottom: "18px",
                }}
                variant="header3"
              >
                Samtykker
              </Text>
              <Text
                style={{
                  margin: 0,
                }}
                variant="body"
              >
                NAF sender jevnlig nyttig informasjon om medlemsfordeler,
                nyheter og tilbud på produkter og tjenester fra NAF og våre
                samarbeidspartnere via e-post og mobil
              </Text>
            </GridCol>
            }
            {props?.isConsentRequired && showConsents && (
              <>
                <GridCol m="12" l="12" xl="12">
                  <Label variant="body" width={"100%"}>
                    NAF kan benytte opplysninger knyttet til mitt medlemskap/kundeforhold for å gi meg tilpassede tilbud og nyttig informasjon
                  </Label>
                  <div className="radio-gap">
                    <RadioButton
                      label="Ja"
                      value="true"
                      onClick={handleChange}
                      disabled={values.hasActiveMembership}
                      checked={
                        values.useInformationConsent.consented === "true"
                      }
                      name="useInformationConsent.consented"
                    />
                    <RadioButton
                      label="Nei"
                      onClick={handleChange}
                      disabled={values.hasActiveMembership}
                      checked={
                        values.useInformationConsent.consented === "false"
                      }
                      name="useInformationConsent.consented"
                      value="false"
                    />
                  </div>
                </GridCol>
                {values.useInformationConsent.consented === "true" && (
                  <GridCol m="12" l="12" xl="12">
                    <Label variant="body" width={"100%"}>
                      Velg kanal
                    </Label>
                    <div className="radio-gap">
                      <Checkbox
                        onChange={(value) =>
                          setFieldValue(
                            "ePost.consented",
                            (!eval(values?.ePost?.consented)).toString()
                          )
                        }
                        disabled={values.hasActiveMembership}
                        label="E-post"
                        name="ePost.consented"
                        checked={values.ePost.consented === "true"}
                      />
                      <Checkbox
                        onChange={(value) =>
                          setFieldValue(
                            "sms.consented",
                            (!eval(values?.sms?.consented)).toString()
                          ).toString()
                        }
                        disabled={values.hasActiveMembership}
                        label="SMS"
                        name="sms.consented"
                        checked={values.sms.consented === "true"}
                      />
                    </div>
                  </GridCol>
                )}
              </>
            )}
          </GridRow>
        </Grid>
      </form>
    </Style>
  );
});

// Add PropTypes validation
Customer.propTypes = {
  initialValueOfCustomer: PropTypes.object,
  mobilePhone: PropTypes.string,
  isConsentRequired: PropTypes.bool.isRequired,
};

export default Customer;
