import moment from "moment";

export class DateUtil {
  static convertToDisplayFormat(date) {
    if (!date) {
      return "";
    }
    return moment.utc(date).format("DD.MM.YYYY");
  }
  static getDay(date) {
    if (!date) {
      return "";
    }
    return moment.utc(date).format("DD");
  }
  static getMonth(date) {
    if (!date) {
      return "";
    }
    return moment.utc(date).format("MM");
  }
  static getYear(date) {
    if (!date) {
      return "";
    }
    return moment.utc(date).format("YYYY");
  }
  static convertToStringDate(day, month, year) {
    return `${year}-${month}-${day}T00:00:00`;
  }
}
