import axios from "axios";
export class ProductService {
  service = "sales-order";

  createSalesOrder(saleOrder) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/create`;

    return axios.post(url, saleOrder);
  }
}
