import axios from "axios";
export class UserService {
  service = "user";
  getAllUser() {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/getusers`;
    return axios.get(url);
  }

  createUser(user) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/create`;

    return axios.post(url, user);
  }

  updateUser(user) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/update`;

    return axios.post(url, user);
  }
  deleteUser(email) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/deleteUser?userEmail=${email}`;

    return axios.post(url, {});
  }
}
