import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

export default function ProtectedRoute(props) {
  const { isAuthenticated } = useAuth0();

  return (
    <Route
      path={props.path}
      render={(data) => {
        return isAuthenticated ? (
          <props.component {...data} {...props}></props.component>
        ) : (
          <Redirect to={{ pathname: "/home" }}></Redirect>
        );
      }}
    />
  );
}

ProtectedRoute.propTypes = {
  path: PropTypes.string,
  component: PropTypes.elementType.isRequired
};
