import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

export default function AuthRoute(props) {
  const { isAuthenticated } = useAuth0();
  console.log(isAuthenticated);
  return (
    <Route
      path={props.path}
      render={(data) =>
        !isAuthenticated ? (
          <props.component {...data}></props.component>
        ) : (
          <Redirect to={{ pathname: "/register" }}></Redirect>
        )
      }
    ></Route>
  );
}

AuthRoute.propTypes = {
  path: PropTypes.string,
  component: PropTypes.elementType.isRequired
};
