export class CommonUtil {
  static getCountries() {
    return [
      {
        name: "Afghanistan +93",
        code: 93,
      },
      {
        name: "Albania +355",
        code: 355,
      },
      {
        name: "Antarktika +123",
        code: 123,
      },
      {
        name: "Norge +47",
        code: 47,
      },
      {
        name: "Sverige +46",
        code: 46,
      },
      {
        name: "Danmark +45",
        code: 45,
      },
    ];
  }

  static validatePhoneNumber(number) {
    let message = "";
    if (!number) {
      message = "Vennligst fyll ut mobilnummer";
    } else if (isNaN(parseFloat(number))) {
      message = "Feltet kan kun inneholde tall";
    } else if (number.length !== 8) {
      message = "Dette er ikke en gyldig mobilnummer";
    }
    return {
      valid: !message.length,
      message,
    };
  }
}
