import axios from "axios";
export class ValidateUserService {
  service = "user";

  validateUser() {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/validateuser`;

    return axios.get(url);
  }
}
