import React, {  useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Text } from "@naf/text";
import { Button } from "@naf/button";
import { useFormik } from "formik";
import { Grid, GridRow, GridCol } from "@naf/grid";
import { Input, Label, Message } from "@naf/input";
import { UserService } from "../../services/UserService";
import { RadioButton } from "@naf/radio-button";
import { useToast, ToastVariant } from "@naf/toast";
import PropTypes from 'prop-types';

const Style = styled.div`
  .radio-gap {
    display: flex;
    width: 100%;
    gap: 20px;
  }
`;
export default function CreateEditUser(props) {
  const userService = new UserService();
  const { showToast, Toast } = useToast();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: props.location.state
      ? props.location.state
      : {
          email: "",
          salesLocationNumber: "",
          isAdmin: "false",
          hasUnpaidInvoice: "false",
          isConsentRequired: "false",
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .nullable()
        .email("Dette er ikke en gyldig e-post")
        .required("Vennligst fyll ut E-post"),
      salesLocationNumber: Yup.string()
        .nullable()
        .required("Fyll inn salgsstedsnummer"),
    }),
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setTouched
  } = formik;

  const saveUser = () => {
    if (Object.keys(errors).length) {
      setTouched(errors);
      return;
    }

    if (props.location.state.mode == "edit") {
      userService.updateUser(values).then(
        (res) => {
          props.history.push("/users");
        },
        (err) => {
          showToast({
            variant: ToastVariant.Error,
            icon: true,
            closable: true,
            message:
              "Du har ikke tilgang til partnerportalen. Kontakt partneransvarlig hos NAF for tilgang",
          });
        }
      );
    } else {
      userService.createUser(values).then(
        (res) => {
          showToast({
            variant: ToastVariant.Success,
            icon: true,
            closable: false,
            message: "User save successfully!",
            timeOut: 3000,
          });
          resetForm();
        },
        (err) => {
          showToast({
            variant: ToastVariant.Error,
            icon: true,
            closable: true,
            message:
              "Du har ikke tilgang til partnerportalen. Kontakt partneransvarlig hos NAF for tilgang",
          });
        }
      );
    }
  };

  useEffect(() => {});
  return (
    <Style>
      <Text
        tag="h2"
        style={{
          marginTop: "0px",
          marginBottom: "40px",
        }}
        variant="header2"
      >
        Brukerinformasjon
      </Text>
      <Toast></Toast>

      {
        <form onSubmit={handleSubmit}>
          <Grid>
            <GridRow columns="12">
              <GridCol m="4" l="4" xl="4">
                <Label>E-post</Label>
                <Input
                  onInput={handleChange}
                  onBlur={handleBlur}
                  value={values.email || ""}
                  name="email"
                  error={errors?.email && touched.email}
                  disabled={props.location.state.mode == "edit"}
                />
                {errors?.email && touched.email && (
                  <Message error> {errors?.email}</Message>
                )}
              </GridCol>
            </GridRow>
            <GridRow columns="12">
              <GridCol m="4" l="4" xl="4">
                <Label>Sales location number</Label>
                <Input
                  onInput={handleChange}
                  onBlur={handleBlur}
                  value={values.salesLocationNumber || ""}
                  name="salesLocationNumber"
                  error={
                    errors?.salesLocationNumber && touched.salesLocationNumber
                  }
                />
                {errors?.salesLocationNumber && touched.salesLocationNumber && (
                  <Message error> {errors?.salesLocationNumber}</Message>
                )}
              </GridCol>
            </GridRow>
            <GridRow columns="12">
              <GridCol m="6" l="6" xl="6">
                <Label>Is Admin? </Label>
                <div className="radio-gap">
                  <RadioButton
                    label="Ja"
                    value="true"
                    onClick={handleChange}
                    name="isAdmin"
                    checked={values.isAdmin.toString() === "true"}
                  />
                  <RadioButton
                    label="Nei"
                    onClick={handleChange}
                    name="isAdmin"
                    value="false"
                    checked={values.isAdmin.toString() === "false"}
                  />
                </div>
                {/* <Checkbox
                  onChange={(value) =>
                    setFieldValue("isAdmin", !eval(values?.isAdmin))
                  }
                  name="isAdmin"
                  checked={values.isAdmin ? true : false}
                /> */}
              </GridCol>
            </GridRow>
            <GridRow columns="12">
              <GridCol m="8" l="8" xl="8">
                <Label>Has Unpaid Invoice ?</Label>
                <div className="radio-gap">
                  <RadioButton
                    label="Ja"
                    value="true"
                    onClick={handleChange}
                    checked={values.hasUnpaidInvoice.toString() === "true"}
                    name="hasUnpaidInvoice"
                  />
                  <RadioButton
                    label="Nei"
                    onClick={handleChange}
                    value="false"
                    checked={values.hasUnpaidInvoice.toString() === "false"}
                    name="hasUnpaidInvoice"
                  />
                </div>
                {/* <Checkbox
                  onChange={(value) =>
                    setFieldValue(
                      "hasUnpaidInvoice",
                      !eval(values?.hasUnpaidInvoice)
                    )
                  }
                  name="hasUnpaidInvoice"
                  checked={values.hasUnpaidInvoice ? true : false}
                /> */}
              </GridCol>
            </GridRow>
            <GridRow columns="12">
              <GridCol m="8" l="8" xl="8">
                <Label>Is Consent Required?</Label>
                <div className="radio-gap">
                  <RadioButton
                    label="Ja"
                    value="true"
                    onClick={handleChange}
                    checked={values.isConsentRequired.toString() === "true"}
                    name="isConsentRequired"
                  />
                  <RadioButton
                    label="Nei"
                    value="false"
                    onClick={handleChange}
                    checked={values.isConsentRequired.toString() === "false"}
                    name="isConsentRequired"
                  />
                </div>
                {/* <Checkbox
                  onChange={(value) =>
                    setFieldValue(
                      "isConsentRequired",
                      !eval(values?.isConsentRequired)
                    )
                  }
                  name="isConsentRequired"
                  checked={values.isConsentRequired ? true : false}
                /> */}
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol m="6" l="6" xl="6">
                <Button                 
                  onClick={saveUser}                 
                >Save</Button>
              </GridCol>
            </GridRow>
          </Grid>
        </form>
      }
    </Style>
  );
}

CreateEditUser.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      mode: PropTypes.string.isRequired, 
    }).isRequired,    
  }).isRequired,
  history: PropTypes.object.isRequired,
};