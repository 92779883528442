import axios from "axios";
export class ProductPriceService {
  service = "product";

  getProductPriceDetails() {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/productspriceforpartnerportal`;

    return axios.get(url);
  }


}
