import React from "react";
import styled from "styled-components";
import { ButtonLink } from "@naf/button-link";

const Style = styled.div`
  height: 92px;
  width: 100%;
  background: #062e2a;
  margin-top: 104px;

  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    height: 100%;
  }
  .copyright {
    color: rgb(255, 255, 255);
    font-family: Questa Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    padding-right: 24px;
  }
`;
export default function Footer() {
  return (
    <Style>
      <div className="footer-container">
        <div className="copyright">© Norges Automobil-Forbund</div>
        <div>
          <ButtonLink
            href="https://www.naf.no/om-naf/personvern/"
            target="_blank"
            variant="inverted"
            text="Privacy"
          />
        </div>
        <div>
          <ButtonLink
            href="https://www.naf.no/om-naf/personvern/"
            target="_blank"
            variant="inverted"
            text="Cookies"
          />
        </div>
      </div>
    </Style>
  );
}
