import React, { useState, useEffect } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@naf/table";
import { Button } from "@naf/button";
import styled from "styled-components";
import { UserService } from "../../services/UserService";
import { Delete, EditOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { Grid, GridRow, GridCol } from "@naf/grid";
import { useToast, ToastVariant } from "@naf/toast";
import { Dialog } from "@naf/dialog";
import { Text } from "@naf/text";
import PropTypes from 'prop-types';

const Style = styled.div`
  .createUser {
    float: right;
  }
  ,
  .createUserBtn {
    text-align: end;
  }
  .dataTable-Col {
    padding: 0px;
  }
 
`;
export default function UserList(props) {
  const history = useHistory();
  const userService = new UserService();
  const [users, setUsers] = useState([]);
  const { showToast, Toast } = useToast();
  const [open, setOpen] = useState(false);
  const [deleteUserEmail, setDeleteUserEmail] = useState("");

  useEffect(() => {

    
    if (!props.isValidatingUser) {
      loadUsers();
    }
  }, [props.isValidatingUser]);

  const loadUsers = () => {
    userService.getAllUser().then((res) => {
      setUsers(res.users);
    });
  };
  const handleEdit = (item) => {
    item.mode = "edit";
    item.isAdmin = item.isAdmin ? "true" :"false"
    item.hasUnpaidInvoice = item.hasUnpaidInvoice ? "true" :"false"
    item.isConsentRequired = item.isConsentRequired ? "true" :"false"

    history.push({ pathname: "/createedituser", state: item });
  };
  const handleDelete = (item) => {
    setOpen(true);
    setDeleteUserEmail(item.email);
  };
  const initValue = () => {
    return {
      email: "",
      salesLocationNumber: "",
      isAdmin: false,
      hasUnpaidInvoice: false,
      isConsentRequired: false,
      mode: "create",
    };
  };
  return (
    <Style>
      <Dialog
        isOpen={open}
        handleClose={() => setOpen(false)}
        title={"Slett bruker"}
        button={
          <>
            <Button
              text="Avbryt"
              onClick={() => setOpen(false)}
              variant="outline"
            />
            <Button
              text="Ok"
              onClick={() => {
                userService.deleteUser(deleteUserEmail).then((res) => {
                  showToast({
                    variant: ToastVariant.Success,
                    icon: true,
                    closable: false,
                    message: "Bruker slettet vellykket!",
                    timeOut: 3000,
                  });
                  setOpen(false);
                  loadUsers();
                  setDeleteUserEmail("");
                });
              }}
            />
          </>
        }
      >
        Er du sikker på at du vil slette bruker?
      </Dialog>
      <Toast></Toast>
      <Grid>
        <GridRow columns="12">
          <GridCol m="10" l="10" xl="10">
            <Text
              tag="h2"
              style={{
                marginTop: "0px",
                marginBottom: "40px",
                textAlign:'start',
              }}
              variant="header2"
            >
              Partner Portal Users
            </Text>
          </GridCol>
          <GridCol m="2" l="2" xl="2" className="createUserBtn">
            <Button             
              onClick={() =>
                history.push({
                  pathname: "/createedituser",
                  state: initValue(),
                })
              }             
            >Create User</Button>
          </GridCol>
        </GridRow>
        <GridRow columns="12">
          <GridCol m="12" l="12" xl="12">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell as="th">Email</TableCell>
                  <TableCell as="th"></TableCell>
                  <TableCell as="th">Sales Location #</TableCell>
                  <TableCell as="th">Is Admin?</TableCell>
                  <TableCell as="th">Has Unpaid Invoice?</TableCell>
                  <TableCell as="th">Consent Required?</TableCell>
                  <TableCell as="th"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.map((v, index) => (
                  <TableRow key={index}>
                    <TableCell>{v.email}</TableCell>
                    <TableCell> </TableCell>
                    <TableCell>{v.salesLocationNumber}</TableCell>
                    <TableCell>{v.isAdmin ? "Yes" : "No"}</TableCell>
                    <TableCell>{v.hasUnpaidInvoice ? "Yes" : "No"}</TableCell>
                    <TableCell>{v.isConsentRequired ? "Yes" : "No"}</TableCell>
                    <TableCell>
                      {
                        <div>
                          <EditOutlined
                            onClick={() => {
                              handleEdit(v);
                            }}
                          />{" "}
                          <Delete
                            onClick={() => {
                              handleDelete(v);
                            }}
                          />
                        </div>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GridCol>
        </GridRow>
      </Grid>
    </Style>
  );
}
UserList.propTypes = {
  isValidatingUser: PropTypes.bool,
};
