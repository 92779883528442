export const ProductInfo = {
  product_with_roadside_assistance: "2020", //Medlemskap med veihjelp
  product_with_MC_roadside_assistance: "2070",// MC medlemskap med veihjelp
  product_with_out_roadside_assistance: "1121", // Medlemskap uten veihjelp
  product_with_out_roadside_assistance_two: "2010",// Medlemskap uten veihjelp
  product_NAF_young: "1131",
  product_NAF_young_2: "1131_erstattet 24.02",
  product_NAF_young_basis: "2050",
};
export const ProductWithRoadsideAssistanceDetails = {
  ProductNumber: "2020",
  ListPrice: 720.0,
  BestPrice: 720.0,
  VolumeDiscount: 0,
  TimeUntilRenewalReduction: 0,
  TotalDiscount: 0,
  NetPrice: 720.0,
};

export const ProductWithOutRoadsideAssistanceDetails = {
  ProductNumber: "1121",
  ListPrice: 660.0,
  BestPrice: 660.0,
  VolumeDiscount: 0,
  TimeUntilRenewalReduction: 0,
  TotalDiscount: 0,
  NetPrice: 660.0,
};
