import axios from "axios";
export class CustomerService {
  service = "customer";

  createCustomer(customer) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/create`;

    return axios.post(url, customer);
  }

  updateCustomer(customer) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/update`;
    return axios.post(url, customer);
  }

  updateConsent(consentRequests) {
    const url = `${process.env.REACT_APP_BASE_URL}/${this.service}/consent`;
    return axios.post(url, consentRequests);
  }
}
