import React, { useState, useEffect } from "react";
import "./styles/global.css";
import "./App.css";
import { GlobalStyle } from "@naf/global-style";
import { ThemeProvider } from "@naf/theme-provider";
import Membership from "./pages/Membership/Membership";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Redirect, Route, Switch } from "react-router-dom";
import Home from "./pages/Home/Home";
import ProtectedRoute from "./hoc/ProtectedRoute";
import AuthRoute from "./hoc/AuthRoute";
import { useAuth0 } from "@auth0/auth0-react";
import { useToast, ToastVariant } from "@naf/toast";
import axios from "axios";
import { ValidateUserService } from "./services/ValidateUserService";
import UserList from "./pages/User/UserList";
import CreateEditUser from "./pages/User/CreateEditUser";

export default function App() {
  const [reset, setReset] = useState(false);
  const { showToast, Toast } = useToast();
  const [isValidatingUser, setIsValidatingUser] = useState(true);
  const [isConsentRequired, setIsConsentRequired] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const { isLoading, getAccessTokenSilently, getIdTokenClaims, logout } =
    useAuth0();
  const { user } = useAuth0();
  const validateUserService = new ValidateUserService();

  useEffect(() => {
    if (user) {
      validateUserService.validateUser().then((res) => {
        if (!res.isValid) {
          showToast({
            variant: ToastVariant.Error,
            icon: true,
            closable: true,
            message:
              "Du har ikke tilgang til partnerportalen. Kontakt partneransvarlig hos NAF for tilgang",
          });
          setTimeout(() => {
            logout({
              returnTo: window.location.origin,
            });
          }, 1000);
        }
        setIsValidatingUser(false);
        if (res.IsConsentRequired !== null) {
          setIsConsentRequired(res.isConsentRequired);
        }
        if (res.isAdmin !== null) {
          setIsAdmin(res.isAdmin);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      localStorage.setItem("email", user.email);
    } else {
      localStorage.setItem("email", null);
    }
  }, [user]);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response?.data?.data ? response?.data?.data : response?.data;
      },
      (error) => {
        const response = error?.response;
        if (response?.status === 401) {
          logout({ returnTo: window.location.origin });
          showToast({
            variant: ToastVariant.Error,
            icon: true,
            message: "Oops! Noe gikk galt",
          });
          return { success: false, result: null };
        }
        return { success: false, result: null };
      }
    );
    const requestInterceptor = axios.interceptors.request.use(
      async (request) => {
        const token = await getAccessTokenSilently();
        const tokenClaims = await getIdTokenClaims();
        request.headers.Authorization = token ? `Bearer ${token}` : "";
        request.headers["Ocp-Apim-Subscription-Key"] =
          process.env.REACT_APP_SUBSCRIPTION_KEY;
        request.headers["Ocp-Apim-Trace"] = true;

        return request;
      },
      (error) => {
        console.error(error);
        return { success: false, result: null };
      }
    );
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, []);

  if (isLoading) {
    return <> </>;
  }

  return (
    <ThemeProvider>
      <GlobalStyle />
      <Toast></Toast>

      <div className="app-container">
        <Header setReset={setReset} isAdmin={isAdmin} />
        <Switch>
          <AuthRoute path="/home" component={Home} />
          <ProtectedRoute
            isConsentRequired={isConsentRequired}
            path="/register"
            reset={reset}
            setReset={setReset}
            component={Membership}
          />
          {isAdmin && (
            <ProtectedRoute
              isValidatingUser={isValidatingUser}
              isConsentRequired={isConsentRequired}
              path="/users"
              reset={reset}
              setReset={setReset}
              component={UserList}
            />
          )}

          {isAdmin && (
            <ProtectedRoute
              isConsentRequired={isConsentRequired}
              path="/createedituser"
              reset={reset}
              setReset={setReset}
              component={CreateEditUser}
            />
          )}

          <Route exact path="/" render={() => <Redirect to="/register" />} />
        </Switch>
      </div>
      <Footer />
    </ThemeProvider>
  );
}
